<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="signzy_color" dark class="pa-2">
        <v-card-text>
          <span class="body-2">{{loaderText}}</span>
          <v-progress-linear indeterminate color="white" class="mt-1"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enablePersistedLoader" persistent width="300">
      <v-card color="signzy_color" dark class="pa-2">
        <v-card-text>
          <span class="body-2">{{persistedLoaderText}}</span>
          <v-progress-linear indeterminate color="white" class="mt-1"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    enablePersistedLoader: false,
    persistedLoaderText: "Please stand by",
    loaderText: "Please stand by",
  }),
  methods: {
    showDialog: function () {
      this.dialog = true;
    },
    hideDialog: function () {
      this.dialog = false;
    },
  },
  created() {
    window.eventBus.$on("vueLoaderTextChange", (text) => {
      this.loaderText = text;
    });
    window.eventBus.$on("vueLoader", (bool) => {
      this.dialog = bool;
      if(!bool){
        this.loaderText = "Please stand by"
      }
    });
    window.eventBus.$on("menuChange", () => {
      this.dialog = true;
    });
    window.eventBus.$on("persistedLoader", (bool, text = "") => {
      this.enablePersistedLoader = bool;
      if(!bool){
        this.persistedLoaderText = "Please stand by";
      } else {
        if(text){
          this.persistedLoaderText = text;
        }
      }
    });
  },
};
</script>