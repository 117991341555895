import Vue from 'vue';
import Vuex from 'vuex';
import enLib from "crypto-js/aes";
import cjsEnc from "crypto-js/enc-utf8";
Vue.use(Vuex);

const isLoadedInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const userDataStore = new Vuex.Store({
  state: {
    userData: undefined,
    userDetails: undefined,
    dataToSave: undefined,
    initiated: false,
    backButtonStatus:false,
    endUserData: {},
    agentData: {},
    callConfig: {},
    allowUserInQueue: false,
    disableVPNCheck: false,
    scheduledCallExpiry: false,
    cafNumber: null,
    subAdminAccessControl: false,
    envValues: {}
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
      if (!isLoadedInIframe()) {
        sessionStorage.configs = enLib.encrypt(JSON.stringify(userData || {}), sessionStorage.userId);
      }
    },
    setEndUserData(state, keyValPair) {
      state.endUserData[keyValPair[0]] = keyValPair[1];
    },
    setUserDetails(state, userDetails) {
      state.userDetails = userDetails
    },
    setDataToSave(state, dataToSave) {
      state.dataToSave = dataToSave
    },
    setInitiated(state, initiated) {
      state.initiated = initiated;
    },
    setAgentData(state, agentData) {
      state.agentData = agentData;
    },
    setBackButtonState(state,status){
      state.backButtonStatus = status
    },
    setDisableVPNCheck(state,check){
      state.disableVPNCheck = check;
    },
    setAllowUserInQueue(state, check){
      state.allowUserInQueue = check;
    },
    setScheduledCallExpiry(state, expiry){
      //expected expiry value is in hours, if set to 0 the default value is set to 15 minutes
      state.scheduledCallExpiry = (parseInt(expiry) ? parseInt(expiry) : (15/60)) * 60 * 60 * 1000;
    },
    setCallConfig(state, config){
      state.callConfig = config;
    },
    setCafNumber(state, number){
      state.cafNumber = number;
    },
    setSubAdminAccessControl(state, access){
      state.subAdminAccessControl = access;
      if (!isLoadedInIframe()) {
        sessionStorage.subAdminConfigs = enLib.encrypt(JSON.stringify(access || {}), sessionStorage.userId);
      }
    },
    setEnvValues(state, envValues) {
      state.envValues = { ...envValues };
    }
  },
  actions: {
    customInitiatePageCleanup(){
      sessionStorage.removeItem("customInitiatePageCleanup");
      sessionStorage.removeItem("oldInitiationId");
      sessionStorage.getItem("initiationId") && sessionStorage.setItem("oldInitiationId", sessionStorage.getItem("initiationId"));
    },
    pageExitCleanUp({}, payload){
      sessionStorage.getItem("onPage") == payload && sessionStorage.removeItem("onPage");
    },
    pageEntry({}, payload){
      sessionStorage.setItem("onPage", payload);
      !sessionStorage.getItem("oldInitiationId") && sessionStorage.getItem("initiationId") && sessionStorage.setItem("oldInitiationId", sessionStorage.getItem("initiationId"))
      !sessionStorage.getItem("oldRequestId") && sessionStorage.getItem("requestId") && sessionStorage.setItem("oldRequestId", sessionStorage.getItem("requestId"))
    },
    postSessionCleanUp(){
      eventBus.$on("prev-session-logging-completed", ()=>{
        let keysToCleanUp = Object.keys(sessionStorage).filter(key => key.endsWith("Refreshed"));
        keysToCleanUp.forEach(key => sessionStorage.removeItem(key));
      });
    },
    sessionCleanUp(context){
      if(sessionStorage.getItem("triggerCustomMIS")){
        sessionStorage.removeItem("triggerCustomMIS");

        if(sessionStorage.getItem("backButtonPressed")){
          sessionStorage.removeItem("backButtonPressed");
          eventBus.$emit("customMIS",{
            "status": "Dropped",
            "subStatus": `Customer dropped: Back button pressed on ${sessionStorage.getItem("fromPage")} Page`,
          });
          sessionStorage.getItem("popStateTriggered") && sessionStorage.removeItem("popStateTriggered")
        } else{
          eventBus.$emit("customMIS",{
            "status": "Dropped",
            "subStatus": `Customer refreshed ${sessionStorage.getItem("fromPage")} Page`,
          });
          context.dispatch("postSessionCleanUp");
        }
        sessionStorage.removeItem("oldInitiationId");
        sessionStorage.removeItem("fromPage");
        sessionStorage.removeItem("oldRequestId");
      }
    }
  },
  getters: {
    userData: state => {
      if (!isLoadedInIframe()) {
        return state.userData || sessionStorage.configs ? JSON.parse(enLib.decrypt(sessionStorage.configs, sessionStorage.userId).toString(cjsEnc) || {}) : undefined;
      } else {
        return state.userData;
      }
    },
    endUserData: state => state.endUserData,
    initiated: state => state.initiated,
    agentData: state => state.agentData,
    userDetails: state => state.userDetails,
    dataToSave: state => state.dataToSave,
    backButtonStatus: state => state.backButtonStatus,
    disableVPNCheck: state => state.disableVPNCheck,
    allowUserInQueue: state => state.allowUserInQueue,
    scheduledCallExpiry: state => state.scheduledCallExpiry,
    callConfig: state => state.callConfig,
    cafNumber: state => state.cafNumber,
    subAdminAccessControl: state => {
      if (!isLoadedInIframe()) {
        return state.subAdminAccessControl || sessionStorage.subAdminConfigs ? JSON.parse(enLib.decrypt(sessionStorage.subAdminConfigs, sessionStorage.userId).toString(cjsEnc) || {}) : undefined;
      } else {
        return state.subAdminAccessControl
      }
    },
    envValues: state => state.envValues
  },
});
